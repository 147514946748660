// Generated by Framer (10753fe)

import { addFonts, cx, CycleVariantState, SVG, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["L3ojLs8io"];

const variantClassNames = {L3ojLs8io: "framer-v-q7a5s5"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "L3ojLs8io", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "L3ojLs8io", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-gUgo7", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-q7a5s5", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"L3ojLs8io"} ref={ref} style={{...style}} transition={transition}><SVG className={"framer-1pce7x7"} data-framer-name={"Vector"} layout={"position"} layoutDependency={layoutDependency} layoutId={"Xh6oTMJKT"} opacity={1} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 15 15\"><path d=\"M 0 5.995 C 0 9.3 2.689 11.989 5.995 11.989 C 7.302 11.989 8.496 11.569 9.48 10.862 L 13.176 14.566 C 13.349 14.739 13.574 14.821 13.815 14.821 C 14.325 14.821 14.678 14.438 14.678 13.935 C 14.678 13.694 14.588 13.477 14.431 13.319 L 10.757 9.623 C 11.531 8.616 11.989 7.362 11.989 5.995 C 11.989 2.689 9.3 0 5.995 0 C 2.689 0 0 2.689 0 5.995 Z M 1.285 5.995 C 1.285 3.395 3.395 1.285 5.995 1.285 C 8.594 1.285 10.705 3.395 10.705 5.995 C 10.705 8.594 8.594 10.705 5.995 10.705 C 3.395 10.705 1.285 8.594 1.285 5.995 Z\" fill=\"rgb(38,38,65)\"></path></svg>"} svgContentId={3253696215} transition={transition} withExternalLayout/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-gUgo7 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-gUgo7 .framer-1e8d1mi { display: block; }", ".framer-gUgo7 .framer-q7a5s5 { height: 15px; overflow: visible; position: relative; width: 15px; }", ".framer-gUgo7 .framer-1pce7x7 { flex: none; height: 15px; left: calc(46.66666666666669% - 15px / 2); position: absolute; top: calc(50.00000000000002% - 15px / 2); width: 15px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 15
 * @framerIntrinsicWidth 15
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 */
const FramerPDsUGjFmK: React.ComponentType<Props> = withCSS(Component, css, "framer-gUgo7") as typeof Component;
export default FramerPDsUGjFmK;

FramerPDsUGjFmK.displayName = "search";

FramerPDsUGjFmK.defaultProps = {height: 15, width: 15};

addFonts(FramerPDsUGjFmK, [])